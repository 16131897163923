import { graphql } from 'gatsby';
import React, { useState } from 'react';
import { sectionList, FormTypes } from '../../constants/queryFragments';
import GlobalStyles from '../../styles/globalStyles';
import loadable from '@loadable/component';
import BootstrapNavbar from '../../components/navigationBar/mainMenu/navigationBar';
import DefaultHeaderMenu from '../../components/defaultHeaderMenu/defaultHeaderMenu';
import * as Styled from './commonPageTemplateStyles';
import BreadcrumbsBlock from '../../common/breadcrumbsBlock/breadcrumbsBlock';
import PageLeftMenu from '../../common/pageLeftMenu/pageLeftMenu';
import BlockLeftPanel from '../../common/blockLeftPanel/blockLeftPanel';
import TopBanner from '../../common/topBanner/topBanner';
import Footer from '../../components/footer/footer';
import SubFooter from '../../components/subFooter/subFooter';
import { Helmet } from 'react-helmet';
import TabletRightMenu from '../../common/tabletRightMenu/tabletRightMenu';
import LogoOG from '../../images/LogoOG.png';

const CommonPageTemplate = ({ data, location }) => {
  const [loaded, setLoaded] = React.useState(false);

  React.useEffect(() => {
    setLoaded(true);
    if (!location?.hash) {
      window.scrollTo(0, 0);
    }
  }, []);
  const path = location?.pathname?.split('/')[1];

  const [showRequestCallBackProp, setShowRequestCallBackProp] = useState(false);
  const handleRequestCallBack = () => {
    setShowRequestCallBackProp(true);
  };
  let pageType = location?.pathname?.split('/').filter((n) => n)[0];
  const hamburgerHeader = {
    residential: 'menu - residential',
    commercial: 'menu - commercial',
    about: 'menu - about island',
    contact: 'menu-contact',
  };
  const MenuBarColor = {
    residential: {
      background: '#ffc423',
      border: '#e4ae1b',
    },
    commercial: {
      background: '#ffc423',
      border: '#e4ae1b',
    },
    locations: {
      background: '#008268',
      border: '#00271F',
    },
    about: {
      background: '#008268',
      border: '#00271F',
    },
    contact: {
      background: '#008268',
      border: '#00271F',
    },
  };
  const sectionsArray =
    data?.allContentfulIslandPetroleum?.edges[0]?.node?.sections;
  return !loaded ? (
    <React.Fragment>
      <GlobalStyles />
      <Helmet>
        <title>
          {data?.allContentfulIslandPetroleum?.edges[0]?.node?.title ??
            'Heating Oil Propane Supplier for Home Commercial | Island Petroleum'}
        </title>

        {data?.allContentfulIslandPetroleum?.edges[0]?.node?.metadata && (
          <meta
            name="description"
            content={
              data?.allContentfulIslandPetroleum?.edges[0]?.node?.metadata
            }
          ></meta>
        )}
        {data?.allContentfulIslandPetroleum?.edges[0]?.node?.hasIndex ===
          false && <meta name="robots" content="noindex" />}
        <meta name="twitter:image" content={LogoOG}></meta>
        <meta property="og:image" content={LogoOG}></meta>
      </Helmet>
      <DefaultHeaderMenu
        showRequestCallBackProp={showRequestCallBackProp}
        setShowRequestCallBackProp={setShowRequestCallBackProp}
      />
      <BootstrapNavbar
        showRequestCallBackProp={showRequestCallBackProp}
        setShowRequestCallBackProp={setShowRequestCallBackProp}
        activeLink={path}
      />
      <TopBanner path={location.pathname} />
      <BreadcrumbsBlock path={location.pathname} />
      <Styled.CommonPageContainer>
        <Styled.InnerContainer>
          <Styled.BodyLeft>
            <PageLeftMenu path={location.pathname} />
            <BlockLeftPanel path={location.pathname} />
          </Styled.BodyLeft>
          <Styled.BodyRight>
            <TabletRightMenu
              MenuBarColor={MenuBarColor}
              pageType={pageType}
              location={location}
              hamburgerHeader={hamburgerHeader}
            />
            {sectionsArray &&
              sectionsArray?.map((section, index) => {
                if (
                  section?.type &&
                  sectionList?.includes(section?.type) &&
                  !FormTypes?.includes(section?.type)
                ) {
                  const SectionComponent = loadable(() =>
                    import(`../../components/${section?.type}/${section?.type}`)
                  );
                  return (
                    <SectionComponent
                      key={index}
                      sectionData={section}
                      handleRequestCallBack={handleRequestCallBack}
                      location={location}
                    />
                  );
                }
              })}
            <Styled.CardStyle>
              <BlockLeftPanel path={location.pathname} />
            </Styled.CardStyle>
          </Styled.BodyRight>
        </Styled.InnerContainer>
      </Styled.CommonPageContainer>
      <Footer showProducts={true} />
      <SubFooter />
    </React.Fragment>
  ) : (
    <React.Fragment>
      <GlobalStyles />
      <Helmet>
        <title>
          {data?.allContentfulIslandPetroleum?.edges[0]?.node?.title ??
            'Heating Oil Propane Supplier for Home Commercial | Island Petroleum'}
        </title>
        {data?.allContentfulIslandPetroleum?.edges[0]?.node?.metadata && (
          <meta
            name="description"
            content={
              data?.allContentfulIslandPetroleum?.edges[0]?.node?.metadata
            }
          ></meta>
        )}
        {data?.allContentfulIslandPetroleum?.edges[0]?.node?.hasIndex ===
          false && <meta name="robots" content="noindex" />}
        <meta name="twitter:image" content={LogoOG}></meta>
        <meta property="og:image" content={LogoOG}></meta>
      </Helmet>
      <DefaultHeaderMenu
        showRequestCallBackProp={showRequestCallBackProp}
        setShowRequestCallBackProp={setShowRequestCallBackProp}
      />
      <BootstrapNavbar
        showRequestCallBackProp={showRequestCallBackProp}
        setShowRequestCallBackProp={setShowRequestCallBackProp}
        activeLink={path}
      />
      <TopBanner path={location.pathname} />
      <BreadcrumbsBlock path={location.pathname} />
      <Styled.CommonPageContainer>
        <Styled.InnerContainer>
          <Styled.BodyLeft>
            <PageLeftMenu path={location.pathname} />
            <BlockLeftPanel path={location.pathname} />
          </Styled.BodyLeft>
          <Styled.BodyRight>
            <TabletRightMenu
              MenuBarColor={MenuBarColor}
              pageType={pageType}
              location={location}
              hamburgerHeader={hamburgerHeader}
            />
            {sectionsArray &&
              sectionsArray?.map((section, index) => {
                if (section?.type && sectionList?.includes(section?.type)) {
                  const SectionComponent = loadable(() =>
                    import(`../../components/${section?.type}/${section?.type}`)
                  );
                  return (
                    <SectionComponent
                      key={index}
                      sectionData={section}
                      handleRequestCallBack={handleRequestCallBack}
                      location={location}
                    />
                  );
                }
              })}
            <Styled.CardStyle>
              <BlockLeftPanel path={location.pathname} />
            </Styled.CardStyle>
          </Styled.BodyRight>
        </Styled.InnerContainer>
      </Styled.CommonPageContainer>
      <Footer showProducts={true} />
      <SubFooter />
    </React.Fragment>
  );
};

export default CommonPageTemplate;

export const pageQuery = graphql`
  query allCommonPagesQuery(
    $pageIdentifier: String!
    $contentfulContent: Boolean!
    $contentfulContentList: Boolean!
    # $contentfulMultimediaText: Boolean!
    $contentfulListImagesWithPath: Boolean!
    $contentfulImageWithPath: Boolean!
    $contentfulFaqs: Boolean!
  ) {
    allContentfulIslandPetroleum(
      filter: { identifier: { type: { eq: $pageIdentifier } } }
    ) {
      edges {
        node {
          name
          identifier {
            type
          }
          slug
          title
          metadata
          node_locale
          hasIndex
          sections {
            ...PageContent @include(if: $contentfulContent)
            ...ContentList @include(if: $contentfulContentList)
            ...FAQContent @include(if: $contentfulFaqs)
            # ...MultimediaText @include(if: $contentfulMultimediaText)
            ...ImagesWithPathList @include(if: $contentfulListImagesWithPath)
            ...ImageWithPath @include(if: $contentfulImageWithPath)
          }
        }
      }
    }
  }
`;
